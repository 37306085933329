import state from "./moduleVehiclesBrandState.js";
import mutations from "./moduleVehiclesBrandMutations.js";
import actions from "./moduleVehiclesBrandActions.js";
import getters from "./moduleVehiclesBrandGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
